exports.components = {
  "component---src-pages-aabningstider-js": () => import("./../../../src/pages/Aabningstider.js" /* webpackChunkName: "component---src-pages-aabningstider-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/Error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-faerdigretter-js": () => import("./../../../src/pages/Faerdigretter.js" /* webpackChunkName: "component---src-pages-faerdigretter-js" */),
  "component---src-pages-forside-js": () => import("./../../../src/pages/Forside.js" /* webpackChunkName: "component---src-pages-forside-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-om-os-js": () => import("./../../../src/pages/OmOs.js" /* webpackChunkName: "component---src-pages-om-os-js" */),
  "component---src-pages-paalaeg-js": () => import("./../../../src/pages/Paalaeg.js" /* webpackChunkName: "component---src-pages-paalaeg-js" */),
  "component---src-pages-storkoeb-js": () => import("./../../../src/pages/Storkoeb.js" /* webpackChunkName: "component---src-pages-storkoeb-js" */)
}

